import React from "react"
import Header from "../components/header"
import Nav from "../components/nav"
import Container from "../components/container"

export default function Test() {
  return (
    <Container>
      <Header headerText="wow" />
      <Header headerText="thea" />
      <p>Such wow. Very React.</p>
    </Container>
  )
}